<script>
export default {
  name: "DevicePage",
  data() {
    return {
      loading: false,
      expanded: []
    };
  },
  computed: {
    headers() {
      return [
        { text: "id", value: "id", sortable: false },
        { text: "Тип", value: "type", sortable: false },
        { text: "Платформа", value: "platform", sortable: false },
        { text: "Статус", value: "status", sortable: false },
        { text: "Результат", value: "result", sortable: false },
        { text: "Создано", value: "created_at", sortable: false }
      ];
    },
    device() {
      return this.$store.state.device || {};
    },
    messages() {
      return this.$store.state.deviceMessages;
    }
  },
  methods: {
    async itemExpanded({ item }) {
      if (item.payload) {
        return;
      }
      try {
        await this.$store.dispatch("getDevicePayload", {
          messageId: item.id
        });
      } catch (e) {
        console.error("fetch device message payload", e);
      }
    },
    async deleteDevice() {
      this.loading = true;
      try {
        this.order = await this.$store.dispatch("deleteDevice", {
          deviceId: this.device.id
        });
        this.$router.replace({ name: "DevicesPage" });
      } catch (e) {
        console.error("delete device", e);
      }
      this.loading = false;
    },
    async fetchMessages() {
      this.loading = true;
      try {
        this.order = await this.$store.dispatch("getDeviceMessages", {
          deviceId: this.device.id
        });
      } catch (e) {
        console.error("fetch device messages", e);
      }
      this.loading = false;
    }
  },
  mounted() {
    if (!this.device.id) {
      this.$router.replace({ name: "DevicesPage" });
    }
    this.fetchMessages();
  }
};
</script>

<style>
.mw-1200 {
  max-width: 1200px;
}
</style>

<template>
  <div class="mx-auto mw-1200">
    <div class="white elevation-1 pa-2 mt-2 layout">
      <v-btn text color="info" @click="$router.push({ name: 'DevicesPage' })"
        >Назад</v-btn
      >
      <v-spacer />
      <v-btn text color="red" @click="deleteDevice">Удалить</v-btn>
      <v-btn text color="green" @click="fetchMessages"
        >Обновить сообщения</v-btn
      >
    </div>
    <div class=" white elevation-1 pa-2 mt-2">
      <div class="text-center">Устройство {{ this.device.vendor_id }}</div>
      <div class="pa-2">
        <div class="layout wrap">
          <div class="flex xs6 pa-1">
            <v-text-field :value="device.id" readonly label="ID" />
          </div>
          <div class="flex xs6 pa-1">
            <v-text-field
              :value="device.sip_number"
              readonly
              label="Номер SIP"
            />
          </div>
        </div>
        <div class="layout wrap">
          <div class="flex xs6 pa-1">
            <v-text-field :value="device.link_id" readonly label="Link ID" />
          </div>
          <div class="flex xs6 pa-1">
            <v-text-field
              :value="device.link_user_id"
              readonly
              label="Link user ID"
            />
          </div>
        </div>
        <div class="layout wrap">
          <div class="flex xs6 pa-1">
            <v-text-field :value="device.platform" readonly label="Платформа" />
          </div>
          <div class="flex xs6 pa-1">
            <v-text-field :value="device.mode" readonly label="Режим" />
          </div>
        </div>
        <div class="layout wrap">
          <div class="flex xs6 pa-1">
            <v-textarea :value="device.data_token" label="Data token" />
          </div>
          <div class="flex xs6 pa-1">
            <v-textarea :value="device.voip_token" label="VOIP token" />
          </div>
        </div>
        <div class="layout wrap">
          <div class="flex xs6 pa-1">
            <v-text-field
              :value="device.topic"
              readonly
              label="Provider topic"
            />
          </div>
          <div class="flex xs6 pa-1">
            <v-text-field
              :value="device.topic_voip"
              readonly
              label="Provider voip topic"
            />
          </div>
        </div>
        <div class="layout wrap">
          <div class="flex xs6 pa-1">
            <v-text-field
              :value="device.updated_at"
              readonly
              label="Обновлено"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="white elevation-1 pa-2 mt-2">
      <v-data-table
        :loading="loading"
        :items="messages"
        :headers="headers"
        :expanded.sync="expanded"
        show-expand
        @item-expanded="itemExpanded"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">{{ item.payload }}</td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
