<script>
export default {
  name: "Layout",
  data() {
    return {
      isLoggedIn: false
    };
  }
};
</script>
<template>
  <v-app style="min-height: 100vh">
    <div class="message-container">
      <v-alert
        v-for="message in $store.state.messages"
        :key="message.id"
        border="right"
        colored-border
        type="error"
        elevation="2"
      >
        <div class="layout align-center">
          <span>{{ message.text }}</span>
          <v-btn icon @click="$store.commit('removeMessage', message.id)"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
      </v-alert>
    </div>
    <v-app-bar app v-if="$store.getters.isAuthenticated" class="white">
      <v-btn text to="/devices">Устройства</v-btn>
      <v-spacer></v-spacer>
      <span class="mr-2">{{ $store.state.user.name }}</span>
      <v-btn text @click="$store.dispatch('logout')"
        ><v-icon>mdi-exit-to-app</v-icon></v-btn
      >
    </v-app-bar>
    <v-main class="grey lighten-5">
      <v-container style="min-height: 100%">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<style>
.message-container {
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 100;
  max-width: 300px;
}
</style>
