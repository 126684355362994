import Vue from "vue";
import VueRouter from "vue-router";
import DevicesPage from "../views/DevicesPage.vue";
import DevicePage from "../views/DevicePage.vue";
import LoginPage from "../views/LoginPage.vue";
import Store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/devices",
    name: "DevicesPage",
    component: DevicesPage
  },
  {
    path: "/devices/:id",
    name: "DevicePage",
    component: DevicePage
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  if (!Store.getters.isAuthenticated && to.name !== "LoginPage") {
    next({ name: "LoginPage" });
    return;
  }
  next();
});
export default router;
